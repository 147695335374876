/* Generic Classes */

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-just-center {
  display: flex;
  justify-content: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-align-just-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Component Specific Classes */

$light-color: white;
$dark-color: black;

.light-theme {
  background-color: $light-color;
  color: $dark-color;
}

.dark-theme {
  background-color: $dark-color;
  color: $light-color;
}

/* Clock */
.clock.main-container {
  margin: auto;
  height: 100%;
  width: 100%;
  font-family: Roboto;

  .main-grid {
    margin: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .clock-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        font-weight: bold;
      }
    }

    .analog-text-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  .analog-clock {
    .react-clock__mark__number {
      // top: 6% !important;
      font-weight: bold;
    }
  }
}

.analog-clock {
  &.light-clock {
    .react-clock__face {
      border-color: $dark-color;
      background-color: $light-color;
    }
    .react-clock__mark__number {
      color: $dark-color;
    }
    .react-clock__hour-hand {
      .react-clock__hand__body {
        background-color: $dark-color;
      }
    }
    .react-clock__minute-hand {
      .react-clock__hand__body {
        background-color: $dark-color;
      }
    }
    .react-clock__second-hand {
      .react-clock__hand__body {
        background-color: red;
      }
    }
    .react-clock__mark__body {
      background-color: $dark-color;
    }
  }
  &.dark-clock {
    .react-clock__face {
      border-color: $light-color;
      background-color: $dark-color;
    }
    .react-clock__mark__number {
      color: $light-color;
    }
    .react-clock__hour-hand {
      .react-clock__hand__body {
        background-color: $light-color;
      }
    }
    .react-clock__minute-hand {
      .react-clock__hand__body {
        background-color: $light-color;
      }
    }
    .react-clock__second-hand {
      .react-clock__hand__body {
        background-color: yellow;
      }
    }
    .react-clock__mark__body {
      background-color: $light-color;
    }
  }
}

/* KonnectClock */
.konnect-clock.main-container {
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 2.29px;
  font-weight: 600;

  .main-grid {
    height: 100%;
    display: flex;
    flex-direction: column;

    .clock-container {
      height: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .footer-container {
      height: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      background-color: rgba(249, 249, 252, 0.8);
      background-image: linear-gradient(
        180deg,
        rgba(245, 245, 245, 0) 0%,
        rgba(245, 245, 245, 1) 100%
      );

      .logo {
        height: 60%;
      }

      .text {
        color: #192344;
        font-size: 250%;
        margin-left: 20px;
      }
    }
  }
}
